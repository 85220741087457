<template>
	<div class="cbox" id="cbox" style="height: auto;">
		<div style="width: 90%;">
			
			
			<el-row :gutter="20">
				<el-col :span="20">
					<div style="text-align: right; line-height: 30px; display: inline-block; text-align: right; width: 120px;">专业名称：</div>
					<el-input type="text" v-model="keyword.keyword" style="width: 300px; margin-right: 10px;" size="small" placeholder="请输入专业名称"></el-input>
					
					<el-button type="primary" icon="el-icon-search" size="small" @click="getList()">查询</el-button>
				</el-col>
			
			
			
			</el-row>
			
			<el-row :gutter="20" style="margin-top: 20px;">
			
			
				<el-col :span="20">
					<div style="text-align: right;display: inline-block; text-align: right; width: 120px;">招生类型：</div>
					<span @click="setTarget('lqtype',item.lqtype)" :class="['lxitem',keyword.lqtype==item.lqtype?'itemckd':''] " v-for="(item, idx) in lqtypes" :key="idx">{{ item.lqtype }}</span>
				</el-col>
			
			
			</el-row>

			<el-row :gutter="20" style="margin-top: 20px;">
		
				<el-col :span="20">
					<div style="text-align: right;display: inline-block; text-align: right; width: 120px;">年份：</div>
					
					<span @click="setTarget('lqyear',item.lqyear)"
						:class="['lxitem',keyword.lqyear==item.lqyear?'itemckd':''] " v-for="(item, idx) in lqyears"
						:key="idx">{{ item.lqyear }}</span>
				</el-col>


			</el-row>


			<el-row :gutter="20" style="margin-top: 20px;">

				<el-col :span="20">
					<div style="text-align: right;line-height: 32px; text-align: right; width: 120px; float: left; vertical-align: top;">省份：</div>
					<div style="margin-left: 120px;">
						<span @click="setTarget('province',item.province)"
							:class="['lxitem',keyword.province==item.province?'itemckd':''] "
							v-for="(item, idx) in provinces" :key="idx">{{ item.province }}</span>
					</div>
				</el-col>



			</el-row>

		</div>




		<div style="padding: 30px;">

			<el-table :data="DataList" size="large" stripe border header-cell-class-name="headerStyle">

				<el-table-column prop="province" label="省份" width="200">
				</el-table-column>

				<el-table-column prop="lqyear" label="年份" width="80">
				</el-table-column>

				<el-table-column prop="kelei" label="科类" width="120">
				</el-table-column>
				<el-table-column prop="pici" label="批次" width="120">
				</el-table-column>
				<el-table-column prop="zhuanye" label="专业名称" min-width="180">
				</el-table-column>
				<!-- <el-table-column prop="shengkongxian" label="省控线" width="100">
				</el-table-column> -->
				<!-- <el-table-column prop="score_max" label="最高分" width="100">
				</el-table-column> -->
				<el-table-column prop="lq_num" label="录取数" width="100">
				</el-table-column>
				<el-table-column prop="score_min" label="最低分" width="100">
				</el-table-column>
				<el-table-column prop="min_score_rank" label="最低分排名" width="100">
				</el-table-column>
			<!-- 	<el-table-column prop="beizhu" label="备注" min-width="100">
				</el-table-column> -->



			</el-table>

		</div>


		<div style="padding-top: 10px;display: flex; flex-direction: row-reverse;">


			<el-pagination @size-change="handleSizeChange" @current-change="paginate" :current-page="page.current_page"
				:page-size="page.per_page"  :page-sizes="[50, 100, 200]" layout="total, sizes, prev, pager, next, jumper"
				:total="page.count">
			</el-pagination>



		</div>




	</div>
</template>

<script>
	export default {
		components: {

		},
		data() {
			return {
				lqtypes: [],
				lqyears: [],
				provinces: [],
				DataList: [],
				keyword: {
					lqtype: "",
					lqyear: "",
					province: "",
					keyword:""
				},
				page: {
					current_page: 1,
					next_page: 1,
					per_page: 50,
					total_page: 1,
					count: 0
				},

			};
		},

		mounted() {
			this.getTypes()
			this.getList();


		},
		methods: {
			resetForm() {
				this.EditItem = {
					lqtype: "",
					lqyear: "",
					province: ""
				}
			},
			onSearch() {
				this.getList();
			},
			getTypes() {
				this.$http.post("/api/score_lqtype", {}).then(res => {
					this.lqtypes = res.data
				})
				this.$http.post("/api/score_years", {}).then(res => {
					this.lqyears = res.data
				})
				this.$http.post("/api/score_province", {}).then(res => {
					this.provinces = res.data
				})
			},
			setTarget(str, val) {
				this.keyword[str] = val

				this.getList();

			},
			getList(page) {
				this.$http.post("/api/score_list_search", {
					keyword: this.keyword,
					page: this.page.current_page,
					page_size: this.page.per_page

				}).then(
					res => {
						this.DataList = res.data.data;
						this.page = res.data.page

						this.$nextTick(e => {
							
							window.parent.postMessage(document.getElementById("cbox").clientHeight, "*")
						})
					})

			},

			paginate(val) {
				this.page.current_page = val
				this.getList()
			},
			handleSizeChange(e) {
			this.page.per_page = e
				this.getList()
			},

			add() {
				this.EditItem = {
						id: 0,
						lqtype: "",
						lqyear: "",
						province: "",
						kelei: "",
						pici: "",
						zhuanye: "",
						shengkongxian: "",
						score_max: "",
						score_min: "",
						score_avg: "",
						beizhu: ""
					},
					this.dialogVisible = true
			},
			updateDetail(e) {
				this.EditItem = e
				this.dialogVisible = true
			},
			handleXlsUpload(e) {

				this.$http.post("/api/import_score_xls", {
					url: e.src
				}).then(res => {
					this.$message({
						type: 'success',
						message: '导入成功'
					});

					this.getList();
				})
			},

			remove(id) {
				this.$confirm('此操作将永久删除该数据, 是否继续?', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(() => {
					this.$http.post("/api/score_delete", {
						id: id
					}).then(res => {
						this.$message({
							type: 'success',
							message: '删除成功'
						});

						this.getList(this.page.current_page);
					})

				}).catch(() => {

				});
			},
			saveForm() {
				this.$http.post("/api/score_update", this.EditItem).then(res => {
					this.$message({
						type: 'success',
						message: '保存成功'
					});
					this.dialogVisible = false

					this.getList();
				})
			}


		}
	}
</script>


<style scoped>
	.lxitem {

		padding: 4px 10px;
		margin-right: 10px;
		cursor: pointer;
		margin-bottom: 10px;
		display: inline-block;
		vertical-align: top;
	}

	.itemckd {
		background-color: #6f95f8;
		color: #fff;
	}
</style>


<style>
	#app {
		height: auto;
	}

	.headerStyle {
		color: #0166b8;
		text-align: left !important;
		background-color: #f2f7fb !important;
		border-bottom: 1px solid #0063b7 !important;
	}

	::-webkit-scrollbar {
		display: none;
	}

	::scrollbar {
		display: none;
	}
</style>